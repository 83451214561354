// Make sure pick and dropoff is same when round trip is enabled and drop off is disabled

import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  GoogleMap,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { RectButton, SquareButton } from '../Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { commonStyles } from '../CustomTextField';
import './TripPlanner.css';

export const TripPlanner = ({
  tripType = 'Transfer',
  roundTrip = false,
  stops = [],
  setStops = () => {},
  setDistance = () => {},
  title = 'Trip Planner',
  setUpdating = () => {},
}) => {
  const { t } = useTranslation('translation');
  const timeoutRef = useRef(null);
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const pickUp = {
      id: 'pickup',
      label: t('trip.pickUp'),
      value: '',
      error: false,
      helperText: ' ',
    };

    const dropOff = {
      id: 'dropoff',
      label: t('trip.dropOff'),
      value: '',
      error: false,
      helperText: ' ',
    };

    const stop = {
      id: 'stop',
      label: t('trip.stop'),
      value: '',
      error: false,
      helperText: ' ',
    };

    if (!roundTrip || tripType === 'Hourly') {
      setStops([pickUp, dropOff]);
    } else {
      setStops([pickUp, stop, dropOff]);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [roundTrip, setStops, t, tripType]);

  const calculateAndDisplayRoute = useCallback(
    async (thisStops) => {
      let stopsValid = true;

      thisStops.forEach((stop) => {
        if (!stop.value) {
          stopsValid = false;
        }
      });

      if (stopsValid) {
        const waypoints = thisStops.slice(1, -1).map((address) => ({
          location: address.value,
          stopover: true,
        }));

        const origin = thisStops[0].value;
        const destination = thisStops[thisStops.length - 1].value;

        try {
          const directionsService = new window.google.maps.DirectionsService();

          directionsService.route(
            {
              origin: origin,
              destination: destination,
              waypoints: waypoints,
              optimizeWaypoints: true,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                let distance = 0;
                result.routes[0].legs.forEach((leg) => {
                  distance += leg.distance.value;
                });
                distance = distance / 1000;
                console.log(distance);
                setDistance(distance);
                setDirections(result);
              } else {
                setDistance(null);
                setDirections(null);
              }
            },
          );
        } catch (error) {}
      }
    },
    [setDistance],
  );

  const handleAddStop = () => {
    const firstPart = stops.slice(0, stops.length - 1);
    const lastPart = stops.slice(stops.length - 1);
    setStops([
      ...firstPart,
      {
        id: 'stop',
        label: t('trip.stop'),
        value: '',
        error: false,
        helperText: ' ',
      },
      ...lastPart,
    ]);
  };

  const handleStopChange = (index, value) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const updatedStops = [...stops];
    updatedStops[index].value = value;
    updatedStops[index].error = false;
    updatedStops[index].helperText = ' ';
    if (roundTrip) {
      updatedStops[updatedStops.length - 1].value = updatedStops[0].value;
    }
    setStops(updatedStops);
    handleMapChange(updatedStops);
  };

  const handleRemoveStop = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const updatedStops = [...stops];
    updatedStops.splice(index, 1);
    setStops(updatedStops);
    handleMapChange(updatedStops);
  };

  const handleMoveStop = (currentIndex, direction) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const arrayLength = stops.length;
    const newIndex =
      direction === 'up'
        ? (currentIndex - 1 + arrayLength) % arrayLength
        : (currentIndex + 1) % arrayLength;
    if (newIndex >= 0 && newIndex < stops.length) {
      const updatedStops = [...stops];
      const temp = updatedStops[currentIndex].value;
      updatedStops[currentIndex].value = updatedStops[newIndex].value;
      updatedStops[newIndex].value = temp;
      if (roundTrip) {
        updatedStops[arrayLength - 1].value = updatedStops[0].value;
      }
      setStops(updatedStops);
      handleMapChange(updatedStops);
    }
  };

  const onLoad = (autocomplete, index) => {
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const updatedStops = [...stops];
      updatedStops[index].value = place.formatted_address;
      setStops(updatedStops);
      handleMapChange(updatedStops);
    });
  };

  const handleMapChange = useCallback(
    (thisStops) => {
      setUpdating(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        calculateAndDisplayRoute(thisStops);
        setUpdating(false);
      }, 1500);
    },
    [calculateAndDisplayRoute, setUpdating],
  );

  const calculateBounds = (directions) => {
    const bounds = new window.google.maps.LatLngBounds();
    directions.routes.forEach((route) => {
      route.bounds.union(route.bounds);
      route.legs.forEach((leg) => {
        leg.steps.forEach((step) => {
          step.path.forEach((point) => {
            bounds.extend(point);
          });
        });
      });
    });
    return bounds;
  };

  return (
    <div className="Trip-container">
      <div className="Trip-head">
        <h4>{title}</h4>
        {tripType !== 'Hourly' && (
          <div style={{ marginBottom: '25px' }}>
            <RectButton onClick={handleAddStop}>
              <div>{t('trip.addStop')}</div>
            </RectButton>
          </div>
        )}
      </div>
      {stops.map((stop, index) => {
        const dropOffUpDisabled = stop.id === 'dropoff' ? !roundTrip : true;
        const lastStopDownDisabled =
          index === stops.length - 2 ? !roundTrip : true;
        const atleastOneStop =
          stop.id === 'stop' && stops.length === 3 ? !roundTrip : true;

        const upCond = stop.value && stop.id !== 'pickup' && dropOffUpDisabled;
        const downCond =
          stop.value && stop.id !== 'dropoff' && lastStopDownDisabled;
        const removeCond = stop.id === 'stop' && atleastOneStop;

        let counter = 0;

        counter += upCond ? 1 : 0;
        counter += downCond ? 1 : 0;
        counter += removeCond ? 1 : 0;

        const padder = `${counter * 56 - (counter - 1) * 5}px`;

        return (
          <Autocomplete
            key={index}
            onLoad={(autoComplete) => onLoad(autoComplete, index)}
          >
            <div key={index} className="Trip-wrapper">
              <TextField
                label={
                  stop.id === 'stop' ? t('trip.stop') + ' ' + index : stop.label
                }
                value={stop.value}
                error={stop.error}
                helperText={stop.helperText}
                disabled={stop.id === 'dropoff' ? roundTrip : false}
                inputProps={{ style: { paddingRight: padder } }}
                onChange={(e) => handleStopChange(index, e.target.value)}
                sx={commonStyles}
              />
              <div className="Trip-btn-box">
                {upCond && (
                  <SquareButton onClick={() => handleMoveStop(index, 'up')}>
                    <ArrowDropUpIcon />
                  </SquareButton>
                )}
                {downCond && (
                  <SquareButton onClick={() => handleMoveStop(index, 'down')}>
                    <ArrowDropDownIcon />
                  </SquareButton>
                )}
                {removeCond && (
                  <SquareButton onClick={() => handleRemoveStop(index)}>
                    <ClearIcon />
                  </SquareButton>
                )}
              </div>
            </div>
          </Autocomplete>
        );
      })}

      <GoogleMap
        mapContainerStyle={{ height: '400px', width: '100%' }}
        center={
          directions
            ? calculateBounds(directions).getCenter().toJSON()
            : { lat: 45.497, lng: -73.636 }
        }
        zoom={12}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
};

export default TripPlanner;
