import React, { useState } from 'react';
import './BoxSelect.css';

export const BoxSelect = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option.title);
    onSelect(option.title);
  };

  return (
    <div className='container-fluid'>
      <div className="row Boxs-container">
        {options.map((option, index) => (
          <div
            key={index}
            className={`col-md-4 Boxs-option ${
              selectedOption === option.title ? 'Boxs-selected' : ''
            }`}
            onClick={() => handleOptionClick(option)}
          >
            <img src={option.image} alt={option.title} />
            <p>{option.title}</p>
          </div>
        ))}
      </div>
    </div>

  );
};

export default BoxSelect;
