import React from 'react';
import Modal from '@mui/material/Modal';
import { RectButton } from '../Button';
import { useTranslation } from 'react-i18next';
import './CustomModal.css';

export const CustomModal = ({
  isOpen,
  onClose = () => {},
  title,
  shouldShowSecondBtn = false,
  secondBtnLabel = 'OK',
  onClickSecond = () => {},
  children,
}) => {
  const { t } = useTranslation('translation');
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="Modal-wrapper">
        <div className="Modal-container">
          <h4 className="Modal-title">{title}</h4>
          <div className="Modal-children">{children}</div>
          <div className="Modal-btnContainer">
            {shouldShowSecondBtn && (
              <RectButton onClick={onClickSecond} variant="primary">
                {secondBtnLabel}
              </RectButton>
            )}
            <RectButton onClick={onClose} variant="secondary">
              {t('reserve.close')}
            </RectButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
