import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadScript } from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { CustomTextField } from '../../components/CustomTextField';
import { CustomSelectField } from '../../components/CustomSelectField';
import { CustomDateTimePicker } from '../../components/CustomDateTimePicker';
import { TripPlanner } from '../../components/TripPlanner';
import { RectButton } from '../../components/Button';
import { apiFetch } from '../../utilities/ApiFetch';
import { validateEmail } from '../../utilities/Functions';
import { CircularLoading } from '../../components/CircularLoading';
import { useTranslation } from 'react-i18next';
import { SNACKVARIANT, initialFieldState } from '../../utilities/Constants';
import { CustomModal } from '../../components/CustomModal';
import { BoxSelect } from '../../components/BoxSelect';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import dayjs from 'dayjs';
import './Reservation.css';

import suv from '../../assets/Vehicle/Cadillac-Escalade-1.png';
import sedan from '../../assets/Vehicle/Lincoln-Continental-1.png';
import ReserveFrontImg from '../../assets/Image/Gold-Currency-Icons.jpg';
import luxvan from '../../assets/Vehicle/Luxvan-1.png';

const libraries = ['places'];

export const Reservation = () => {
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleOpenViewModal = () => {
    if (validateForm()) {
      setIsViewModalOpen(true);
    }
  };

  const handleCloseViewModal = () => {
    setAgreePrice(false);
    setAgreeTerm(false);
    setCarSelected(null);
    setIsViewModalOpen(false);
  };

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(initialFieldState);
  const [lastName, setLastName] = useState(initialFieldState);
  const [phone, setPhone] = useState(initialFieldState);
  const [email, setEmail] = useState(initialFieldState);
  const [confirmEmail, setConfirmEmail] = useState(initialFieldState);
  const [passengerCount, setPassengerCount] = useState(1);
  const [luggageCount, setLugguageCount] = useState(0);
  const [isRoundTrip, setIsRoundTrip] = useState('No');
  const [isSameDay, setIsSameDay] = useState(true);
  const [tripType, setTripType] = useState('Transfer');
  const [pickupDateTime, setPickupDateTime] = useState(initialFieldState);
  const [hourCount, setHourCount] = useState(3);
  const [hourCountHelper, setHourCountHelper] = useState(t('reserve.hourKm3'));
  const [stops, setStops] = useState([]);
  const [returnStops, setReturnStops] = useState([]);
  const [specialRequest, setSpecialRequest] = useState(initialFieldState);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [agreePrice, setAgreePrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [returnDateTime, setReturnDateTime] = useState(initialFieldState);
  const [distance, setDistance] = useState(0);
  const [returnDistance, setReturnDistance] = useState(0);
  const [carSelected, setCarSelected] = useState(null);
  const [price, setPrice] = useState({});
  const [isPickUpdating, setIsPickUpdating] = useState(false);
  const [isReturnUpdating, setIsReturnUpdating] = useState(false);
  const [arrivalFlight, setArrivalFlight] = useState(initialFieldState);
  const [departureFlight, setDepartureFlight] = useState(initialFieldState);

  const checkTime = (dateTime) => {
    if (dateTime) {
      const midnight = dateTime.startOf('day');
      const fourThirtyAM = midnight.clone().add(4, 'hours').add(30, 'minutes');
      return dateTime.isAfter(midnight) && dateTime.isBefore(fourThirtyAM);
    }

    return false;
  };

  useEffect(() => {
    let tripPrice = 0;

    if (tripType === 'Transfer' || tripType === 'Airport Transfer') {
      const totalDist = distance + returnDistance;
      let initPrice = 0;
      let distPrice = 0;
      let initFee = 0;

      if (carSelected === 'Suv') {
        initPrice = 4.5;
        distPrice = 3.1659;
        initFee = 50;
      } else if (carSelected === 'Sedan') {
        initPrice = 3.5;
        distPrice = 2.63045;
        initFee = 50;
      } else {
        initPrice = 5.667;
        distPrice = 4.5435;
        initFee = 125;
      }

      tripPrice += 30 * initPrice;
      tripPrice += initFee;

      if (totalDist > 30) {
        tripPrice += (totalDist - 30) * distPrice;
      } else {
        tripPrice +=
          (stops.length -
            2 +
            (returnStops.length > 1 ? returnStops.length - 2 : 0)) *
          50;
      }
    } else {
      let hourlyPrice = 0;

      if (carSelected === 'Suv') {
        hourlyPrice = 150;
      } else if (carSelected === 'Sedan') {
        hourlyPrice = 120;
      } else {
        hourlyPrice = 200;
      }

      tripPrice += hourCount * hourlyPrice;
    }

    const gst = tripPrice * 0.05;
    const qst = tripPrice * 0.09975;

    let temp = {
      vehicle: tripPrice,
      gst: gst,
      qst: qst,
    };

    if (
      (pickupDateTime.value && checkTime(pickupDateTime.value)) ||
      (returnDateTime.value && checkTime(returnDateTime.value))
    ) {
      temp.overnight = 50;
    }

    if (
      stops[0]?.value?.toLowerCase().includes('yul') ||
      returnStops[0]?.value?.toLowerCase().includes('yul') ||
      stops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)') ||
      returnStops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)')
    ) {
      temp.airportFee = 15;
    }

    let total = 0;
    for (let key in temp) {
      total += temp[key];
    }
    temp.total = total;

    setPrice(temp);
  }, [
    carSelected,
    distance,
    returnDistance,
    stops,
    returnStops,
    hourCount,
    tripType,
    pickupDateTime.value,
    returnDateTime.value,
  ]);

  const handleChange = (setter, value) => {
    setter({ ...initialFieldState, value });
  };

  const formatPhoneNumber = (input) => {
    const match = input.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return input;
  };

  const handlePhoneChange = (value) => {
    setPhone({ ...initialFieldState, value });
  };

  const validateForm = () => {
    let isValid = true;

    if (firstName.value === '') {
      setFirstName({
        value: '',
        error: true,
        helperText: t('reserve.nameRequired'),
      });
      isValid = false;
    }

    if (lastName.value === '') {
      setLastName({
        value: '',
        error: true,
        helperText: t('reserve.nameRequired'),
      });
      isValid = false;
    }

    if (phone.value === '') {
      setPhone({
        value: '',
        error: true,
        helperText: t('reserve.phoneRequired'),
      });
      isValid = false;
    } else if (!/^[0-9+()\- ]+$/.test(phone.value)) {
      setPhone({
        value: '',
        error: true,
        helperText: t('reserve.phoneInvalid'),
      });
      isValid = false;
    }

    if (email.value === '') {
      setEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailRequired'),
      });
      isValid = false;
    } else if (!validateEmail(email.value)) {
      setEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailInvalid'),
      });
      isValid = false;
    }

    if (confirmEmail.value === '') {
      setConfirmEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailRequired'),
      });
      isValid = false;
    } else if (email.value !== confirmEmail.value) {
      setConfirmEmail({
        value: '',
        error: true,
        helperText: t('reserve.emailNotMatch'),
      });
      isValid = false;
    }

    if (pickupDateTime.value === '') {
      setPickupDateTime({
        value: '',
        error: true,
        helperText: t('reserve.dateRequired'),
      });
      isValid = false;
    }

    let stopValid = true;

    const updatedStops = stops.map((stop) => {
      if (stop.value.trim() === '') {
        isValid = false;
        stopValid = false;
        return {
          ...stop,
          error: true,
          helperText: t('reserve.locationRequired'),
        };
      } else {
        return { ...stop, error: false, helperText: ' ' };
      }
    });
    setStops(updatedStops);

    if (stopValid) {
      if (
        stops.length === 2 &&
        stops[0].value === stops[1].value &&
        (tripType === 'Transfer' || tripType === 'Airport Transfer')
      ) {
        isValid = false;
        setStops([
          {
            ...stops[0],
          },
          {
            ...stops[1],
            error: true,
            helperText: t('reserve.locationNotMatch'),
          },
        ]);
      } else if (
        stops.length > 2 &&
        stops[0].value === stops[stops.length - 1].value
      ) {
        let cond = true;

        for (let i = 1; i < stops.length - 1; i++) {
          if (stops[i].value !== stops[i + 1].value) {
            cond = false;
          }
        }

        if (cond) {
          isValid = false;
          const temp = stops;
          temp[temp.length - 2].error = true;
          temp[temp.length - 2].helperText = t('reserve.locationNotMatch');
          setStops(temp);
        }
      }
    }

    if (isRoundTrip === 'Yes') {
      if (returnDateTime.value === '') {
        setReturnDateTime({
          value: '',
          error: true,
          helperText: t('reserve.dateRequired'),
        });
        isValid = false;
      }

      let returnStopValid = true;

      const updatedStops = returnStops.map((stop) => {
        if (stop.value.trim() === '') {
          isValid = false;
          returnStopValid = false;
          return {
            ...stop,
            error: true,
            helperText: t('reserve.locationRequired'),
          };
        } else {
          return { ...stop, error: false, helperText: ' ' };
        }
      });
      setReturnStops(updatedStops);

      if (returnStopValid) {
        if (
          returnStops.length === 2 &&
          returnStops[0].value === returnStops[1].value &&
          (tripType === 'Transfer' || tripType === 'Airport Transfer')
        ) {
          isValid = false;
          setReturnStops([
            {
              ...returnStops[0],
            },
            {
              ...returnStops[1],
              error: true,
              helperText: t('reserve.locationNotMatch'),
            },
          ]);
        } else if (
          returnStops.length > 2 &&
          returnStops[0].value === returnStops[returnStops.length - 1].value
        ) {
          let cond = true;

          for (let i = 1; i < returnStops.length - 1; i++) {
            if (returnStops[i].value !== returnStops[i + 1].value) {
              cond = false;
            }
          }

          if (cond) {
            isValid = false;
            const temp = returnStops;
            temp[temp.length - 2].error = true;
            temp[temp.length - 2].helperText = t('reserve.locationNotMatch');
            setReturnStops(temp);
          }
        }
      }
    }

    if (!isValid) {
      handleAlert(t('reserve.fillFields'), SNACKVARIANT.WARNING);
    } else if (!distance) {
      handleAlert(t('reserve.addressOk'), SNACKVARIANT.WARNING);
      return false;
    }

    return isValid;
  };

  const validateModal = () => {
    let isValid = true;

    if (carSelected === null) {
      handleAlert(t('reserve.selectCarRequired'), SNACKVARIANT.WARNING);
      isValid = false;
    }

    if (agreeTerm === false || agreePrice === false) {
      handleAlert(t('reserve.agreeRequired'), SNACKVARIANT.WARNING);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (validateModal()) {
        handleCloseViewModal();
        setIsLoading(true);
        const data = {
          firstName: firstName.value,
          lastName: lastName.value,
          phone: phone.value,
          email: email.value,
          passengerCount,
          luggageCount,
          isRoundTrip,
          tripType,
          departureFlight: departureFlight.value,
          arrivalFlight: arrivalFlight.value,
          pickupDateTime: pickupDateTime.value,
          hourCount,
          stops,
          returnStops,
          specialRequest: specialRequest.value,
          returnDateTime: returnDateTime.value,
          distance,
          returnDistance,
          carSelected,
          price,
        };

        const response = await apiFetch('post', '/reserve', data);

        setIsLoading(false);

        if (response.status === 'SUCCESS') {
          handleAlert(t('reserve.reserveSuccess'), SNACKVARIANT.SUCCESS);
          navigate('/');
        } else if (response.reason === 'EMPTY_INPUTS') {
          handleAlert(t('reserve.fillFields'), SNACKVARIANT.WARNING);
        } else {
          handleAlert(t('reserve.reserveFail'), SNACKVARIANT.ERROR);
        }
      }
    } catch (error) {
      handleAlert(t('reserve.reserveFail'), SNACKVARIANT.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isRoundTrip === 'Yes') {
      if (pickupDateTime.value && returnDateTime.value) {
        setIsSameDay(
          dayjs(pickupDateTime.value).isSame(
            dayjs(returnDateTime.value),
            'day',
          ),
        );
      } else {
        setIsSameDay(true);
      }
    } else {
      setIsSameDay(true);
    }
  }, [isRoundTrip, pickupDateTime.value, returnDateTime.value]);

  return (
    <>
      <WelcomeBanner
        imgSrc={ReserveFrontImg}
        mainMsg={
          <>
            {t('reserve.reserveLow')}{' '}
            <span style={{ color: '#DDB989' }}> {t('reserve.online')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 0px 30px 0px' }}>
        <div className="row">
          <div className="col-lg-8">
            <div>
              <div className="Reserve-gray-box">
                {/* NAME */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.firstName')}
                      variant="outlined"
                      value={firstName.value}
                      error={firstName.error}
                      helperText={firstName.helperText}
                      onValueChange={(e) =>
                        handleChange(setFirstName, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      label={t('reserve.lastName')}
                      variant="outlined"
                      value={lastName.value}
                      error={lastName.error}
                      helperText={lastName.helperText}
                      onValueChange={(e) =>
                        handleChange(setLastName, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {/* NAME */}

                {/* EMAIL */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.email')}
                      variant="outlined"
                      value={email.value}
                      error={email.error}
                      helperText={email.helperText}
                      onValueChange={(e) =>
                        handleChange(setEmail, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      label={t('reserve.confirmEmail')}
                      variant="outlined"
                      value={confirmEmail.value}
                      error={confirmEmail.error}
                      helperText={confirmEmail.helperText}
                      onValueChange={(e) =>
                        handleChange(setConfirmEmail, e.target.value)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {/* EMAIL */}

                {/* PHONE */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      label={t('reserve.phone')}
                      variant="outlined"
                      value={formatPhoneNumber(phone.value)}
                      error={phone.error}
                      helperText={phone.helperText}
                      onValueChange={(e) => handlePhoneChange(e.target.value)}
                      required={true}
                    />
                  </div>
                </div>
                {/* PHONE */}

                <hr className="Res-divider" />

                {/* PASSENGER AND LUGGAGE */}
                <div className="Reserve-padder">
                  <div>
                    <CustomTextField
                      type="number"
                      label={t('reserve.passengers')}
                      inputProps={{ min: 1 }}
                      value={passengerCount}
                      onValueChange={(e) => setPassengerCount(e.target.value)}
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomTextField
                      type="number"
                      label={t('reserve.luggage')}
                      inputProps={{ min: 0 }}
                      value={luggageCount}
                      onValueChange={(e) => setLugguageCount(e.target.value)}
                      required={true}
                    />
                  </div>
                </div>
                {/* PASSENGER AND LUGGAGE */}

                {/* TRIP TYPE AND ROUND TRIP*/}
                <div className="Reserve-padder">
                  <div>
                    <CustomSelectField
                      label={t('reserve.roundTrip')}
                      options={
                        tripType !== 'Hourly'
                          ? [
                              {
                                key: t('reserve.yes'),
                                value: 'Yes',
                              },
                              { key: t('reserve.no'), value: 'No' },
                            ]
                          : [{ key: t('reserve.no'), value: 'No' }]
                      }
                      value={isRoundTrip}
                      onValueChange={(e) => {
                        if (e === 'No') {
                          setReturnStops([]);
                          setReturnDateTime(initialFieldState);
                          setReturnDistance(0);
                        }
                        setIsRoundTrip(e);
                      }}
                    />
                  </div>
                  <div>
                    <CustomSelectField
                      label={t('reserve.tripType')}
                      defaultValue="transfer"
                      options={[
                        { key: t('reserve.transfer'), value: 'Transfer' },
                        {
                          key: t('reserve.airportTransfer'),
                          value: 'Airport Transfer',
                        },
                        { key: t('reserve.hourly'), value: 'Hourly' },
                      ]}
                      value={tripType}
                      onValueChange={(e) => {
                        if (e === 'Hourly') {
                          setIsRoundTrip('No');
                          setReturnStops([]);
                          setReturnDateTime(initialFieldState);
                          setReturnDistance(0);
                        }
                        setTripType(e);
                      }}
                    />
                  </div>
                </div>
                {/* TRIP TYPE AND ROUND TRIP*/}

                {/* FLIGHT NUMBER */}
                {tripType === 'Airport Transfer' && (
                  <div className="Reserve-padder">
                    <div>
                      <CustomTextField
                        label={t('reserve.departureFlightNumber')}
                        variant="outlined"
                        value={departureFlight.value}
                        error={departureFlight.error}
                        helperText={departureFlight.helperText}
                        onValueChange={(e) =>
                          handleChange(setDepartureFlight, e.target.value)
                        }
                        required={false}
                      />
                    </div>
                    <div>
                      <CustomTextField
                        label={t('reserve.arrivalFlightNumber')}
                        variant="outlined"
                        value={arrivalFlight.value}
                        error={arrivalFlight.error}
                        helperText={arrivalFlight.helperText}
                        onValueChange={(e) =>
                          handleChange(setArrivalFlight, e.target.value)
                        }
                        required={false}
                      />
                    </div>
                  </div>
                )}
                {/* FLIGHT NUMBER */}

                {/* PICK UP DATE TIME AND HOUR COUNT */}
                <div className="Reserve-padder">
                  <div>
                    <CustomDateTimePicker
                      label={t('reserve.date')}
                      value={pickupDateTime.value}
                      error={pickupDateTime.error}
                      helperText={pickupDateTime.helperText}
                      onValueChange={(e) => handleChange(setPickupDateTime, e)}
                    />
                  </div>
                  {tripType === 'Hourly' ? (
                    <div>
                      <CustomTextField
                        type="number"
                        label={t('reserve.hours')}
                        inputProps={{
                          min: 3,
                          max: 24,
                        }}
                        value={hourCount}
                        onValueChange={(e) => {
                          if (e.target.value < 3) {
                            setHourCount(3);
                            handleAlert(
                              t('reserve.minHour', { min: 3 }),
                              SNACKVARIANT.WARNING,
                            );
                          } else if (e.target.value > 24) {
                            handleAlert(
                              t('reserve.maxHour'),
                              SNACKVARIANT.WARNING,
                            );
                            setHourCount(24);
                          } else {
                            if (e.target.value < 6) {
                              setHourCountHelper(t('reserve.hourKm3'));
                            } else if (
                              e.target.value >= 6 &&
                              e.target.value < 10
                            ) {
                              setHourCountHelper(t('reserve.hourKm6'));
                            } else {
                              setHourCountHelper(t('reserve.hourKm10'));
                            }
                            setHourCount(e.target.value);
                          }
                        }}
                        helperText={hourCountHelper}
                        required={true}
                      />
                    </div>
                  ) : isRoundTrip === 'Yes' ? (
                    <div>
                      <CustomDateTimePicker
                        label={t('reserve.returnDateTime')}
                        value={returnDateTime.value}
                        error={returnDateTime.error}
                        helperText={returnDateTime.helperText}
                        onValueChange={(e) =>
                          handleChange(setReturnDateTime, e)
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {/* PICK UP DATE TIME AND HOUR COUNT */}

                <hr className="Res-divider" />

                {/* STOPS */}
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
                  libraries={libraries}
                >
                  <TripPlanner
                    tripType={tripType}
                    roundTrip={isSameDay && isRoundTrip === 'Yes'}
                    stops={stops}
                    setStops={setStops}
                    setDistance={setDistance}
                    title={
                      isRoundTrip === 'Yes' && !isSameDay
                        ? t('reserve.initialTripPlanner')
                        : t('reserve.tripPlanner')
                    }
                    setUpdating={setIsPickUpdating}
                  />

                  {!isSameDay && isRoundTrip === 'Yes' && (
                    <>
                      <hr className="Res-divider" />
                      <TripPlanner
                        tripType={'Transfer'}
                        roundTrip={false}
                        stops={returnStops}
                        setStops={setReturnStops}
                        setDistance={setReturnDistance}
                        title={t('reserve.returnTripPlanner')}
                        setUpdating={setIsReturnUpdating}
                      />
                    </>
                  )}
                </LoadScript>
                {/* STOPS */}

                <hr className="Res-divider" />

                {/* SPECIAL REQUESTS */}
                <div>
                  <CustomTextField
                    label={t('reserve.specialRequests')}
                    variant="outlined"
                    multiline={true}
                    rows={5}
                    value={specialRequest.value}
                    error={specialRequest.error}
                    helperText={specialRequest.helperText}
                    onValueChange={(e) =>
                      handleChange(setSpecialRequest, e.target.value)
                    }
                  />
                </div>
                {/* SPECIAL REQUESTS */}

                <div className="Res-reserve-btn">
                  <RectButton
                    onClick={handleOpenViewModal}
                    loading={isPickUpdating || isReturnUpdating}
                  >
                    {t('reserve.continue')}
                  </RectButton>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 Reserve-info-box">
            <div className="Reserve-gray-box">
              <span className="Playball Reserve-span">{t('reserve.info')}</span>
              <ul className="Reserve-info-ul">
                <li>{t('reserve.infoDesc1')}</li>
                <li>{t('reserve.infoDesc2')}</li>
                <li>{t('reserve.infoDesc3')}</li>
                <li>{t('reserve.infoDesc4')}</li>
                <li>{t('reserve.infoDesc5')}</li>
              </ul>
              <span className="Playball Reserve-span">
                {t('reserve.cancel')}
              </span>
              <p>
                {t('reserve.cancelDesc')}{' '}
                <Link to="/About/Terms">Terms and Conditions</Link>
              </p>
              <span className="Playball Reserve-span">
                {t('reserve.contactUs')}
              </span>
              <p style={{ margin: '0px' }}>
                {t('reserve.phone')}: 514-975-3134
              </p>
              <p style={{ margin: '0px' }}>
                {t('reserve.email')}: info@platinumchauffeur.ca
              </p>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <CircularLoading />}
      <CustomModal
        isOpen={isViewModalOpen}
        onClose={handleCloseViewModal}
        title={t('reserve.confirmRes')}
        shouldShowSecondBtn={true}
        secondBtnLabel={t('reserve.reserveUp')}
        onClickSecond={handleSubmit}
      >
        <BoxSelect
          options={[
            { image: suv, title: 'Suv' },
            { image: sedan, title: 'Sedan' },
            { image: luxvan, title: t('reserve.luxuryVan') },
          ]}
          onSelect={(e) => {
            setCarSelected(e);
          }}
        />

        {carSelected && (
          <div className="Res-modal-item">
            <h5>{t('reserve.priceEstimate')}</h5>
            <p>
              <span>{carSelected + ': '}</span>
              <span>{'$' + price.vehicle.toFixed(2)}</span>
            </p>
            {price.overnight && (
              <p>
                <span>{t('reserve.overnightCharge') + ': '}</span>
                <span>{'$' + price.overnight.toFixed(2)}</span>
              </p>
            )}
            {price.airportFee && (
              <p>
                <span>{t('reserve.airportFee') + ': '}</span>
                <span>{'$' + price.airportFee.toFixed(2)}</span>
              </p>
            )}
            <p>
              <span>{t('reserve.gst') + ': '}</span>
              <span>{'$' + price.gst.toFixed(2)}</span>
            </p>
            <p>
              <span>{t('reserve.qst') + ': '}</span>
              <span>{'$' + price.qst.toFixed(2)}</span>
            </p>
            <p className="Res-total-price">
              <span>{t('reserve.total') + ': '}</span>
              <span>{'$' + price.total.toFixed(2)}</span>
            </p>
          </div>
        )}

        {/* TERM AGREEMENT */}
        <div style={{ padding: '25px 0px 10px 0px' }}>
          <FormControlLabel
            required
            control={<Checkbox onClick={() => setAgreeTerm(true)} />}
            label={t('reserve.agreeTerm')}
          />
        </div>
        <div>
          <FormControlLabel
            required
            control={<Checkbox onClick={() => setAgreePrice(true)} />}
            label={t('reserve.agreePrice')}
          />
        </div>
        {/* TERM AGREEMENT */}
      </CustomModal>
    </>
  );
};

export default Reservation;
