import React from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import './Gallery.css';

import img1 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-1.JPG';
import img2 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-2.JPG';
import img3 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-3.JPG';
import img4 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-4.JPG';
import img5 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-5.JPG';
import img6 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-6.JPG';
import img7 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-7.JPG';
import img8 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-8.JPG';
import img9 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-9.JPG';
import img10 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-10.JPG';
import img11 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-11.JPG';
import img12 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-12.JPG';
import img13 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-13.JPG';
import img14 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-14.JPG';
import img15 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-15.JPG';
import img16 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-16.JPG';
import img17 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-17.JPG';
import img18 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-18.JPG';
import img19 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-19.JPG';
import img20 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-20.JPG';
import img21 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-21.JPG';
import img22 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-22.JPG';
import img23 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-23.JPG';
import img24 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-24.JPG';
import img25 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-25.JPG';
import img26 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-26.JPG';
import img27 from '../../assets/Gallery/Black-Cadillac-Escalade-2022-27.JPG';
import GalleryFrontImg from '../../assets/Image/Museum-Gallery.jpg';

export const Gallery = () => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <WelcomeBanner
        imgSrc={GalleryFrontImg}
        mainMsg={
          <>
            {t('gallery.our')}
            <span style={{ color: '#DDB989' }}> {t('gallery.gallery')}</span>
          </>
        }
      />
      <div className="container" style={{ paddingTop: '50px' }}>
        <div className="row Gallery-row">
          <div className="column Gallery-column">
            <img src={img1} alt="Black Cadillac Escalade 2022" />
            <img src={img2} alt="Black Cadillac Escalade 2022" />
            <img src={img3} alt="Black Cadillac Escalade 2022" />
            <img src={img4} alt="Black Cadillac Escalade 2022" />
            <img src={img5} alt="Black Cadillac Escalade 2022" />
            <img src={img6} alt="Black Cadillac Escalade 2022" />
            <img src={img7} alt="Black Cadillac Escalade 2022" />
          </div>
          <div className="column Gallery-column">
            <img src={img8} alt="Black Cadillac Escalade 2022" />
            <img src={img9} alt="Black Cadillac Escalade 2022" />
            <img src={img10} alt="Black Cadillac Escalade 2022" />
            <img src={img11} alt="Black Cadillac Escalade 2022" />
            <img src={img12} alt="Black Cadillac Escalade 2022" />
            <img src={img13} alt="Black Cadillac Escalade 2022" />
            <img src={img14} alt="Black Cadillac Escalade 2022" />
          </div>
          <div className="column Gallery-column">
            <img src={img15} alt="Black Cadillac Escalade 2022" />
            <img src={img16} alt="Black Cadillac Escalade 2022" />
            <img src={img17} alt="Black Cadillac Escalade 2022" />
            <img src={img18} alt="Black Cadillac Escalade 2022" />
            <img src={img19} alt="Black Cadillac Escalade 2022" />
            <img src={img20} alt="Black Cadillac Escalade 2022" />
            <img src={img21} alt="Black Cadillac Escalade 2022" />
          </div>
          <div className="column Gallery-column">
            <img src={img22} alt="Black Cadillac Escalade 2022" />
            <img src={img23} alt="Black Cadillac Escalade 2022" />
            <img src={img24} alt="Black Cadillac Escalade 2022" />
            <img src={img25} alt="Black Cadillac Escalade 2022" />
            <img src={img26} alt="Black Cadillac Escalade 2022" />
            <img src={img27} alt="Black Cadillac Escalade 2022" />
          </div>
        </div>
      </div>

      <div className="Gallery-our-gallery">
        <h1 style={{ margin: '0px', fontWeight: '900' }}>
          {t('gallery.moreOnOur')}{' '}
          <a
            href="https://www.instagram.com/platinum_chauffeurmtl/"
            style={{ color: '#DDB989', textDecoration: 'none' }}
          >
            Instagram!
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Gallery;
