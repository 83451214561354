import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
      return;
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
      setTimeout(() => {
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [location.pathname]);

  return children;
};

export default ScrollToTop;
