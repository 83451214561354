import React, { useEffect, useRef, useState } from 'react';
import { ImageSlider } from '../../components/ImageSlider';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import './Vehicle.css';

import CadillacEscalade from '../../assets/Vehicle/Cadillac-Escalade-1.png';
import CEFrontView from '../../assets/Image/Cadillac-Escalade-Front-View.JPG';
import CESideView from '../../assets/Image/Cadillac-Escalade-Side-View.JPG';
import CEDriverView from '../../assets/Image/Cadillac-Escalade-Driver-View.JPG';
import CEPassengerView from '../../assets/Image/Cadillac-Escalade-Passenger-View.JPG';
import LincolnContinental1 from '../../assets/Vehicle/Lincoln-Continental-1.png';
import Bus1 from '../../assets/Vehicle/Bus-1.png';
import MercedezBenzSprinter1 from '../../assets/Vehicle/Mercedes-Benz-Sprinter-1.png';
import MercedezBenzSprinter2 from '../../assets/Vehicle/Mercedes-Benz-Sprinter-2.png';
import MercedezBenzSprinter3 from '../../assets/Vehicle/Mercedes-Benz-Sprinter-3.jpg';
import MiniCoach211 from '../../assets/Vehicle/Mini-coach-21-1.png';
import MiniCoach212 from '../../assets/Vehicle/Mini-coach-21-2.png';
import MiniCoach301 from '../../assets/Vehicle/Mini-Coach-30-1.png';
import MiniCoach302 from '../../assets/Vehicle/Mini-Coach-30-2.jpg';
import StretchLimousine1 from '../../assets/Vehicle/Stretch-Limousine-1.png';
import VehicleFrontImg from '../../assets/Image/Two-Cadillac-Escalade.jpg';
import Luxvan1 from '../../assets/Vehicle/Luxvan-1.png';
import Luxvan2 from '../../assets/Vehicle/Luxvan-2.png';

export const Vehicle = () => {
  const { t } = useTranslation('translation');
  const elementsToAnimate = useRef([]);
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (elementsToAnimate.current[idx]) {
        const elementTop =
          elementsToAnimate.current[idx].getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;
        if (elementTop < viewportHeight * 0.95) {
          elementsToAnimate.current[idx].classList.add('Idx-left-animate');
          setIdx((prevIdx) => prevIdx + 1);
          if (elementsToAnimate.current.length === idx + 1) {
            window.removeEventListener('scroll', handleScroll);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementsToAnimate, idx, setIdx]);

  return (
    <div>
      <WelcomeBanner
        imgSrc={VehicleFrontImg}
        mainMsg={
          <>
            {t('vehicle.our')}
            <span style={{ color: '#DDB989' }}> {t('vehicle.vehicles')}</span>
          </>
        }
      />
      <div className="container" style={{ paddingTop: '50px' }}>
        <hr id="suv" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="suvid"
            images={[
              CadillacEscalade,
              CEFrontView,
              CESideView,
              CEDriverView,
              CEPassengerView,
            ]}
            name="SUV"
            description={t('vehicle.suvDesc')}
            passengers={`5 ${t('vehicle.comfortable')}, 6 ${t(
              'vehicle.maximum',
            )}`}
            bags={`5 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="sedan" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="sedanid"
            images={[LincolnContinental1]}
            name="Sedan"
            description={t('vehicle.sedanDesc')}
            passengers={`3 ${t('vehicle.maximum')}`}
            bags={`3 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="limousine" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="limousineid"
            images={[StretchLimousine1]}
            name="Limousine"
            description={t('vehicle.limousineDesc')}
            passengers={`8 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="luxvan" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="luxvanid"
            images={[Luxvan1, Luxvan2]}
            name={t('vehicle.luxvan')}
            description={t('vehicle.luxvanDesc')}
            passengers={`8 ${t('vehicle.maximum')}`}
            bags={`12 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="van" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="vanid"
            images={[
              MercedezBenzSprinter1,
              MercedezBenzSprinter2,
              MercedezBenzSprinter3,
            ]}
            name="Sprinter Van"
            description={t('vehicle.vanDesc')}
            passengers={`12 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="mini-coach" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="mini-coachid"
            images={[MiniCoach211, MiniCoach212]}
            name="Mini Coach"
            description={t('vehicle.miniDesc')}
            passengers={`21 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="coach-bus" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="busid"
            images={[MiniCoach301, MiniCoach302]}
            name="Coach Bus"
            description={t('vehicle.busDesc')}
            passengers={`30 ${t('vehicle.maximum')}`}
          />
        </div>
        <hr id="motorcoach" className="Vehicle-divider" />
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
        >
          <ImageSlider
            id="motorid"
            images={[Bus1]}
            name="Motorcoach"
            description={t('vehicle.motorDesc')}
            passengers={`56 ${t('vehicle.maximum')}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
